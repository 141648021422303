module.exports = {
  en: {
    path: 'en',
    name: 'United States - English',
    default: true
  },
  es: {
    path: 'es',
    name: 'United States - Espa&#241;ol'
  }
};
