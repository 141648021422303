/**
 * LocaleSwitcherModal provides clickable elements for changing the locale.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { changePath } from '../util/localizer';
import { onClickA11y } from '../util/pagetools';
import locales from '../../content/locales';
import Safe from './Safe';

export default function LocaleSwitcherModal(props) {
  const [pickLocale, setPickLocale] = useState(false);
  const isMultiLingual = Object.keys(locales).length>1;
  const globeIcon = (<svg xmlns="http://www.w3.org/2000/svg" className="box-30" viewBox="0 0 20 20" stroke="white">
      <path fillRule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clipRule="evenodd" />
    </svg>);

  const localeLinks = Object.keys(locales).map(lang => ({
    name: locales[lang].name,
    path: changePath(locales[lang].path, props.currentpath, props.ctx)
  }));

  useEffect(() => {
    function onEsc(e) { if (pickLocale && e.keyCode===27) setPickLocale(false); }
    document.addEventListener('keydown', onEsc, true);
    return () => { document.removeEventListener('keydown', onEsc); };
  }, [pickLocale]);

  if (isMultiLingual)
    return (
      <>
        <button className="locale-switcher mr-12" onClick={(e) => {setPickLocale(true)}}>{globeIcon}</button>
        <Dialog show={pickLocale} closerfn={() => {setPickLocale(false);}} locales={localeLinks}></Dialog>
      </>
    );
  return null;
};

function Dialog(props) {
  function onCancel(e) { if (props.closerfn) props.closerfn(); }

  const clickAttributes = onClickA11y(onCancel);
  const closeIcon = (<svg xmlns="http://www.w3.org/2000/svg" className="box-25" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>);

  if (props.show) return (
    <div {...clickAttributes} className="dialog-root">
      <button className="ne dialog-close-icon" onClick={onCancel}>{closeIcon}</button>
      <div className="dialog-container">
        <div className="text-lg-center">
          {props.locales.map((item, index) => (
            <div key={`lang-${index}`}><Safe type="link" to={item.path} content={item.name} /></div>
          ))}
        {props.children}
        </div>
      </div>
    </div>
  );
  return <></>;
};
